export default {
  methods: {
    handleScroll(event) {
      if (this.isAnnotation) {
        this.isAnnotation = false
      }
      this.startIndex = Math.round(event.target.scrollTop / this.cellHeight)
      this.topPaddingHeight = Math.max(this.startIndex * this.cellHeight, 0)
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.topPaddingHeight - this.bufferHeight,
        0
      )
    },
  },
  watch: {
    'filterData.length': function (val) {
      if (val < this.startIndex) {
        this.startIndex = 0
      }
      this.topPaddingHeight = Math.max(this.startIndex * this.cellHeight, 0)
      this.totalHeight = val * this.cellHeight
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.topPaddingHeight - this.bufferHeight,
        0
      )
    },
  },
  data() {
    return {
      cellHeight: 42,
      viewportHeight: 0,
      totalHeight: 0,
      tolerance: 5,
      toleranceHeight: 42 * 5,
      bufferHeight: 0,
      startIndex: 0,
      topPaddingHeight: 0,
      bottomPaddingHeight: 0,
      bufferedItems: 0,
    }
  },
  computed: {
    visibleItemsRoutes() {
      return this.filteredRoutes.slice(
        this.startIndex,
        this.startIndex + this.bufferedItems
      )
    },
    visibleItemsRaces() {
      return this.filterData.slice(
        this.startIndex,
        this.startIndex + this.bufferedItems
      )
    },
  },
  mounted() {
    console.log('zalalala')
    this.totalHeight = this.filterData.length * this.cellHeight
    this.viewportHeight = this.$refs['scrolltable'].clientHeight
    this.bufferHeight = this.viewportHeight + 2 * this.toleranceHeight
    this.bottomPaddingHeight = Math.max(this.totalHeight - this.bufferHeight, 0)
    this.bufferedItems = Math.round(
      this.viewportHeight / this.cellHeight + 2 * this.tolerance
    )
  },
}
