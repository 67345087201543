<template>
  <div class="overflow-hidden">
    <div
      class="absolute z-30 right-5 left-5 top-5 bottom-5 ml-5 rounded-xl"
      style="width: 500px; left: 68px"
      v-if="isMainShowen"
    >
      <div
        class="bg-white relative overflow-hidden rounded-xl shadow"
        style="width: 499px; height: 95vh"
      >
        <div
          class="cont bg-white pt-3 px-8 transition-all ease-out duration-500"
          :class="scrolled ? 'shadow' : ''"
        >
          <div class="flex justify-between items-center pb-4 mt-4">
            <div class="font-bold text-darkblue text-xl">
              {{ $t('routesTrips.title') }}
            </div>
            <div class="flex items-center justify-end">
              <img
                class="mr-5"
                alt=""
                v-if="(notRole || role === 'CONTROLLER') && isRoutes"
                src="../../assets/icons/history.svg"
                style="width: 18px; cursor: pointer"
                @click="$emit('show-history', { objects: 'routes' })"
              /><img
                alt=""
                class="mr-5"
                v-if="(notRole || role === 'CONTROLLER') && isRaces"
                src="../../assets/icons/history.svg"
                style="width: 18px; cursor: pointer"
                @click="$emit('show-history', { objects: 'trips' })"
              /><i
                class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
                @click="handlClose"
              ></i>
            </div>
          </div>
          <div
            class="flex justify-start items-center pb-4 mt-4 border border-t-0 border-l-0 border-r-0"
          >
            <skif-search
              @searching="handlSearchRoutes"
              v-if="isRoutes"
              v-model="searchRoutes"
              :placeholder="$t('search')"
              style="height: 35px"
              class="w-full"
            ></skif-search>
            <skif-search
              @searching="handlSearchRaces"
              v-else
              v-model="searchRaces"
              :placeholder="$t('search')"
              class="w-full"
              style="height: 35px"
            ></skif-search>
          </div>
          <div
            class="flex justify-between mt-1 mb-2"
            style="color: #91b4e7; height: 60px"
          >
            <div class="flex">
              <button
                type="button"
                class="mr-8 buttons outline-none"
                :class="isRoutes ? 'isActive' : ''"
                @click="switchTab('routes')"
              >
                {{ $t('routesTrips.routes') }}</button
              ><button
                class="buttons"
                type="button"
                :class="isRaces ? 'isActive' : ''"
                @click="switchTab('races')"
              >
                {{ $t('routesTrips.trips') }}</button
              ><span
                class="text-darkblue leading-10 cursor-pointer"
                style="
                  font-size: 14px;
                  color: #91b4e7;
                  height: 100%;
                  margin-left: 195px;
                  margin-top: 10px;
                "
              >
                <transition name="fade">
                  <div v-if="isRoutes && routeSelected.length > 0">
                    <el-tooltip
                      :open-delay="700"
                      effect="dark"
                      :content="$t('delete')"
                      placement="bottom"
                      :offset="2"
                      :visible-arrow="false"
                      ><button
                        class="group relative link outline-none"
                        type="button"
                        @click="deleteRoutes"
                      >
                        <svg
                          class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#91B4E7"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                          ></path>
                        </svg>
                        <skif-badge
                          flights="flights"
                          :value="routeSelected.length"
                        ></skif-badge></button
                    ></el-tooltip>
                  </div>
                </transition> </span
              ><span
                class="text-darkblue leading-10 cursor-pointer"
                style="font-size: 14px; color: #91b4e7; margin-top: 10px"
              >
                <transition name="fade">
                  <div v-if="isRaces && raceSelected.length > 0">
                    <el-tooltip
                      :open-delay="700"
                      effect="dark"
                      :content="$t('delete')"
                      placement="bottom"
                      :offset="2"
                      :visible-arrow="false"
                      ><button
                        class="h-9 group relative link outline-none"
                        type="button"
                        @click="deleteRaces"
                      >
                        <svg
                          class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200"
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#91B4E7"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"
                          ></path>
                        </svg>
                        <skif-badge
                          flights="flights"
                          :value="raceSelected.length"
                        ></skif-badge></button
                    ></el-tooltip>
                  </div>
                </transition>
              </span>
            </div>
            <div class="flex" style="margin-right: 6px">
              <div class="flex" v-if="role === 'READER'">
                <img
                  alt=""
                  src="../../assets/icons/addNewDisabled.svg"
                  style="width: 22px"
                />
              </div>
              <div class="flex" v-else-if="isRoutes">
                <img
                  alt=""
                  class="cursor-pointer addNew"
                  src="../../assets/icons/addNew.svg"
                  style="width: 22px"
                  @click="
                    cleanMap(),
                      (isRoute = true),
                      (isEditingNow = false),
                      (isMainShowen = false)
                  "
                />
              </div>
              <div class="flex" v-else>
                <img
                  alt=""
                  class="cursor-pointer addNew"
                  src="../../assets/icons/addNew.svg"
                  style="width: 22px"
                  @click="
                    ;(isRace = true),
                      (isEditingNow = false),
                      (isMainShowen = false)
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-y-auto pb-6" style="height: 100%">
          <transition name="fade">
            <div class="w" style="width: 100%" v-if="isRoutes">
              <div class="header" style="display: flex">
                <div class="w-10 ml-8">
                  <skif-checkbox-old
                    class="p-2"
                    @click.native="(event) => event.stopPropagation()"
                    style="margin-left: -8px"
                    v-model="isAllRoutesChecked"
                    :indeterminate="isIndeterminateRoutes"
                    :disabled="!filteredRoutes.length"
                  ></skif-checkbox-old>
                </div>
                <div
                  class="bg-white fontLato top-0 sticky text-left"
                  style="width: 150px"
                >
                  <span
                    class="text-darkblue leading-10 cursor-pointer mr-2"
                    style="font-size: 14px; color: #91b4e7"
                    >{{ $t('routesTrips.name') }}</span
                  >
                </div>
              </div>
              <ul
                class="body"
                move-class="transition duration-1000"
                style="width: 100%"
              >
                <li
                  class="px-8 hover_bg-hoverrow"
                  v-for="(route, index) in filteredRoutes"
                  :key="index"
                  style="
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div class="flex">
                    <skif-checkbox-old
                      @click.native="(event) => event.stopPropagation()"
                      v-model="routeSelected"
                      :label="route.id"
                    ></skif-checkbox-old>
                  </div>
                  <div
                    class="cursor-pointer"
                    style="width: 70%"
                    @click="showGeozonesOnMapRoute(route)"
                  >
                    <el-tooltip
                      :open-delay="700"
                      effect="dark"
                      :content="route.name"
                      placement="bottom"
                      :offset="0"
                      :visible-arrow="false"
                      ><span
                        class="flex leading-10 nameTable ellipsis"
                        style="max-width: 100%"
                        >{{ route.name }}</span
                      ></el-tooltip
                    >
                  </div>
                  <div class="pl-10 cursor-pointer align-left">
                    <img
                      alt=""
                      @click="
                        updateRoute(route)
                        isEditingRoute = true
                        isRoute = false
                        isMainShowen = false
                      "
                      src="../../assets/icons/edit-flight.svg"
                      style="width: 18px; margin-left: 6px"
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div class="w" style="width: 100%" v-else>
              <div class="header" style="display: flex">
                <div class="w-10 ml-8">
                  <skif-checkbox-old
                    class="p-2"
                    @click.native="(event) => event.stopPropagation()"
                    v-model="isAllRacesChecked"
                    style="margin-left: -8px"
                    :indeterminate="isIndeterminateRace"
                    :disabled="!filteredRaces.length"
                  ></skif-checkbox-old>
                </div>
                <div
                  class="bg-white fontLato top-0 sticky text-left"
                  style="width: 150px"
                >
                  <span
                    class="text-darkblue leading-10 cursor-pointer mr-2"
                    style="font-size: 14px; color: #91b4e7"
                    >{{ $t('routesTrips.name') }}</span
                  >
                </div>
              </div>
              <ul
                class="body"
                move-class="transition duration-1000"
                style="width: 100%"
              >
                <li
                  class="hover_bg-hoverrow px-8"
                  v-for="(race, index) in filteredRaces"
                  :key="index"
                  style="
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div>
                    <skif-checkbox-old
                      v-model="raceSelected"
                      :label="race.id"
                      @click.native="(event) => event.stopPropagation()"
                    ></skif-checkbox-old>
                  </div>
                  <div
                    class="cursor-pointer"
                    style="width: 70%"
                    @click="showGeozonesOnMapRace(race)"
                  >
                    <el-tooltip
                      :open-delay="700"
                      effect="dark"
                      :content="race.name"
                      placement="bottom"
                      :offset="0"
                      :visible-arrow="false"
                      ><span
                        class="leading-10 nameTable ellipsis"
                        style="max-width: 100%; display: inline-block"
                        >{{ race.name }}</span
                      ></el-tooltip
                    >
                  </div>
                  <div class="pl-10 cursor-pointer align-left">
                    <img
                      alt=""
                      @click="
                        updateRace(race)
                        ;(isEditingNow = true),
                          (isRace = false),
                          (isMainShowen = false)
                      "
                      src="../../assets/icons/edit-flight.svg"
                      style="width: 18px; margin-left: 6px"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </transition>
        </div>
        <div
          class="mt-3 w-full justify-self-center absolute bottom-0 bg-white flex justify-center pt-2"
        >
          <el-pagination
            v-if="isRoutes"
            @prev-click="prevPage"
            @next-click="nextPage"
            @current-change="handleCurrentChangeRoutes"
            layout="prev, pager, next"
            :pageSize="pageSize"
            :total="maxRoutes"
          ></el-pagination>
          <el-pagination
            v-else
            @prev-click="prevPage"
            @next-click="nextPage"
            @current-change="handleCurrentChangeRaces"
            layout="prev, pager, next"
            :pageSize="pageSize"
            :total="maxRaces"
          ></el-pagination>
        </div>
      </div>
    </div>
    <transition name="fade" mode="in-out">
      <addRace
        v-if="isRace"
        v-on:close=";(isRace = false), (isMainShowen = true)"
      ></addRace>
    </transition>
    <transition name="fade">
      <addRoute
        v-if="isRoute"
        v-on:close=";(isRoute = false), (isMainShowen = true)"
      ></addRoute>
    </transition>
    <transition name="fade">
      <editRace
        v-if="isEditingNow"
        @show-history='(params) =&gt; $emit("show-history", params)'
        v-on:close=";(isEditingNow = false), (isMainShowen = true)"
      ></editRace>
    </transition>
    <transition name="fade">
      <editRoute
        v-if="isEditingRoute"
        @show-history='(params) =&gt; $emit("show-history", params)'
        v-on:close=";(isEditingRoute = false), (isMainShowen = true)"
      ></editRoute>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import tripsAndRoutesMixin from '@/mixins/tripsAndRoutes.js'
import virtualScroll from './list/virtualScrollTrips'

export default {
  components: {
    addFromGeozones: () => import('@/components/race/addFromGeozones'),
    addRace: () => import('@/components/race/race'),
    editRace: () => import('@/components/race/editRace'),
    addRoute: () => import('@/components/race/routes/createRoute'),
    editRoute: () => import('@/components/race/routes/editRoute')
  },
  mixins: {
    tripsAndRoutesMixin,
    virtualScroll
  },
  data() {
    return {
      isEditingNow: false,
      isEditingRoute: false,
      raceData: [],
      isMainShowen: true,
      isIndeterminate: false,
      routeSelected: [],
      raceSelected: [],
      isRaces: false,
      isRoutes: true,
      scrolled: false,
      isRace: false,
      isRoute: false,
      searchRoutes: '',
      searchRaces: '',
      isInArray: false,
      race: null,
      pointsToClean: [],
      pageNumber: 1,
      pageSize: 100,
      maxRoutes: 0,
      maxRaces: 0,
      formData: {
        from: 0,
        count: 100,

        conditions: [{ name: '', value: '' }]
      }
    }
  },
  computed: {
    ...mapGetters('login', ['role', 'notRole']),
    ...mapState('races', {
      races: (state) => state.races,
      maxRacesRows: (state) => state.maxRows,
      pointsToDrawRace: (state) => state.pointsToDraw
    }),
    ...mapState('routes', {
      routes: (state) => state.routes,
      maxRoutesRows: (state) => state.maxRows
    }),
    ...mapState('routes', {
      route: (state) => state.route
    }),
    ...mapState('routes', {
      pointsToDraw: (state) => state.pointsToDraw
    }),
    isAllRoutesChecked: {
      get() {
        return (
          this.routeSelected.length >= this.filteredRoutes.length &&
          this.filteredRoutes.length
        )
      },
      set(value) {
        this.routeSelected = value ? this.routesId : []
      }
    },
    isAllRacesChecked: {
      get() {
        return (
          this.raceSelected.length >= this.filteredRaces.length &&
          this.filteredRaces.length
        )
      },
      set(value) {
        this.raceSelected = value ? this.raceIds : []
      }
    },
    raceIds() {
      return this.filteredRaces.map((k) => k.id)
    },
    routesId() {
      return this.filteredRoutes.map((k) => k.id)
    },
    filteredRoutes() {
      return this.routes
    },
    filteredRaces() {
      return this.races
    },
    isIndeterminateRace() {
      return (
        this.raceSelected.length > 0 &&
        this.raceSelected.length < this.filteredRaces.length
      )
    },
    isIndeterminateRoutes() {
      return (
        this.routeSelected.length > 0 &&
        this.routeSelected.length < this.filteredRoutes.length
      )
    }
  },
  methods: {
    switchTab(val) {
      if (val === 'routes') {
        this.isRoutes = true
        this.isRaces = false
        this.pageNumber = 1

        this.cleanList()
      } else {
        this.pageNumber = 1
        console.log('switch tab race', this.pageNumber)
        this.isRoutes = false
        this.isRaces = true
      }
    },
    handlClose() {
      this.$emit('close')
      this.cleanMap()
      console.log('closed')
    },
    handlSearchRoutes(val) {
      this.searchRoutes = val
      this.formData.conditions = [{ field: 'name', value: this.searchRoutes }]
      this.getDataRoutes()
    },
    handlSearchRaces(val) {
      this.searchRaces = val
      this.formData.conditions = [{ field: 'name', value: this.searchRaces }]

      this.getDataRaces()
    },
    handleCurrentChangeRoutes(val) {
      this.pageNumber = val
      this.formData.from = (this.pageNumber - 1) * this.pageSize
      this.getDataRoutes()
      console.log('Next Page Loaded!!', (this.pageNumber - 1) * this.pageSize)
    },
    handleCurrentChangeRaces(val) {
      this.pageNumber = val
      this.formData.from = (this.pageNumber - 1) * this.pageSize
      this.getDataRaces()
      console.log(
        'Next Page Loaded Races!!',
        (this.pageNumber - 1) * this.pageSize
      )
    },
    getDataRoutes() {
      this.$store.dispatch('routes/GET_ROUTES', this.formData)
    },
    getDataRaces() {
      this.$store.dispatch('races/GET_RACES', this.formData)
    },
    nextPage() {
      this.pageNumber++
    },
    prevPage() {
      this.pageNumber--
    },

    cleanList() {
      // val==='routes' ? this.routeSelected = [] : this.raceSelected = []
    },
    deleteRoutes() {
      this.$store.dispatch('routes/DELETE_ROUTES', this.routeSelected)
      this.routeSelected = []
      this.cleanMap()
    },
    deleteRaces() {
      this.$store.dispatch('races/DELETE_RACES', this.raceSelected)
      this.cleanMap()

      this.raceSelected = []
      // console.log('edit race', this.raceSelected)
    },
    handleScroll(a) {
      this.scrolled = a.target.scrollTop > 0
    },
    showGeozonesOnMapRoute(route) {
      this.$store.dispatch('routes/GET_POINTS_ROUTE', { route, type: 'route' })
      this.pointsToClean = route
      // console.log('this. route', this.pointsToClean)
    },
    showGeozonesOnMapRace(race) {
      // console.log('check latlng here', race)
      this.$store.dispatch('routes/GET_POINTS_ROUTE', { race, type: 'race' })
      this.pointsToClean = race
      // console.log(' race points to draw ', race)
    },

    cleanMap() {
      this.$store.commit('routes/clean_map', this.pointsToDraw)
    },

    updateRace(race) {
      console.log('edit race ылша 4,58')
      this.$store.dispatch('races/GET_RARCE_ID', race)
    },
    updateRoute(route) {
      this.$store.dispatch('routes/GET_ROUTE_ID', route)
    }
  },
  beforeMount() {
    console.log('race destroyed')
  },
  beforeCreate() {},
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.maxRoutes = this.maxRoutesRows
        this.maxRaces = this.maxRacesRows
      }, 2000)
    })
    // await this.$store.dispatch('races/GET_RACES')
    // await this.$store.dispatch('routes/GET_ROUTES')
  }
}
</script>
<style scoped>
.addNew {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}
.addNew:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.buttons {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #91b4e7;
  mix-blend-mode: normal;
}
.buttons:focus {
  outline: none;
}
.isActive {
  color: #3c5e8f;

  z-index: 9999;
}
.fade-enter-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
